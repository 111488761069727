/** @jsx jsx */
import { jsx, Themed } from "theme-ui"
import Panel from "../components/panel/panel"
import Layout from "../components/layout/layout"
import Metadata from "../components/metadata/metadata"
import Link from "../components/link/link"

export default function NotFound() {
  return (
    <Layout headerbg="bgGray">
      <Metadata title="404 Not Found" />
      <Panel
        sx={{
          bg: "bgGray",
          py: ["3rem", "6rem", "8rem"],
        }}
      >
        <div
          sx={{
            gridColumnStart: ["1", "unset"],
            gridColumnEnd: ["-1", "span 4", "span 5"],
            pt: ["3rem", "unset"],
            fontSize: ["1.5rem", "1.6rem", "2rem"],
            lineHeight: [1.47, 1.5, 1.6],
          }}
        >
          <Themed.h1
            className="scaleText"
            sx={{
              "--max-font-size": [2.6, 3.8, 5.8],
              mb: ["2.8rem", "6rem", "8rem"],
            }}
          >
            This isn’t the page you’re looking for.
          </Themed.h1>
          <Themed.p>
            We’re sorry — it looks like something went wrong. Try heading back
            to our <Link to="/">home page</Link>. We’re sure you’ll find what
            you need there.
          </Themed.p>
        </div>
      </Panel>
    </Layout>
  )
}
